<template>
  <div v-if="isshowgrid  && (bkdata.length > 0)">
    <va-card title="Bundle List">
      <template slot="actions" v-if="(getRole() == 'ADMIN') || (getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER') || (getRole() ==  'SUPPORT') || (getRole() == 'OPERATOR') || (getRole() ==  'HEPI_OPERATOR')">
        <va-button color="#A87D32" icon="entypo entypo-export" v-if="ifCheckboxValue" @click="exportFile()">Export</va-button>
      </template>
      <template slot="actions" v-if="adminRole || operatorRole">
        <va-button  color="success" icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class=" flex md2">
          <va-select
            placeholder="Select Type"
            class="mt-3"
            v-model= "filterType"
            textBy ="name"
            :options= "filterTypeArr"
            @input="getTypeFilter(filterType)"
          />
        </div>
      </div>
            <va-button
          :color="selectOtns.color"
          class="select_all"
          v-if="selectFlag"
          small
          :icon="selectOtns.icon"
          @click="selectOtns.click">
          {{selectOtns.value}}
         </va-button>
      <div class="flex xs12 md12">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs grow v-model="tabValue" style="width: 500px;">
                <va-tab v-for="title in tabTitles" :key="title">
                {{title}}
                </va-tab>
              </va-tabs>
            </div>
          </div>

          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            clickable
          >
          <template slot="checkbox" v-if="bundleflag" slot-scope="props">
              <va-checkbox
              :value="props.rowData.checkbox_value"
              @input="select(props.rowData)"
            />
          </template>
            <template slot="add_on" slot-scope="props">
              <va-badge
                v-if="(props.rowData.add_on == true)"
                class="e-badge e-badge-primary size_1"
                color="#28A745"
              >
                Enable
              </va-badge>
              <va-badge
                v-if="(props.rowData.add_on == false)"
                class="e-badge e-badge-primary size_1"
                color="#DC3545"
              >
                Disable
              </va-badge>
            </template>
            <template slot="iptv" slot-scope="props">
              <div v-if="(props.rowData.iptv == true)">IPTV</div>
              <div v-if="(props.rowData.iptv == false)">OTT</div>
            </template>
            <template slot="packages" slot-scope="props">
              <center>
                <img src="@/assets/Channel.png" title="Click to view!" v-if="props.rowData.channel" @click="showchannellist(props.rowData)" class="grid_image"/>
              </center>
            </template>
            <template slot="ListBp" slot-scope="props">
              <center>
                <img src="@/assets/GridBundle.png" title="Click to view!" v-if="props.rowData.list_bundle_pack" @click="showbplist(props.rowData)" class="grid_image"/>
              </center>
            </template>
            <template slot="actions" slot-scope="props">
              <va-button flat small color="gray" v-if="(adminRole  && props.rowData.bundle_retries.length == 0 && (props.rowData.bundle_type != 'resellerpackage'))  || (operatorRole && (props.rowData.bundle_type == 'custompackage'))" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
            </template>
            <template slot="information" slot-scope="props">
              <center>
                <va-button flat small color="gray" title="Click to view!" v-if="props.rowData" <i class="entypo
                  entypo-users va-icon entypo entypo-users" @click="showinformation(props.rowData)"></i>
                </va-button>
              </center>
            </template>
          </va-data-table>
        </div>
      </div>
      <div>
        <va-modal
          v-model="isShowPackages"
          size="small"
          cancelText=""
          @ok="list()"
          no-outside-dismiss
          class="flex md12"
        >
          <template #header>
            <h2 v-if="channelFlag">Channel Packages</h2>
            <h2 v-if="!channelFlag">Bundle Packages</h2>
          </template>
          <va-button flat small color="danger" icon="entypo entypo-cancel" class="model_cancel" @click="list()"></va-button><br>
          <div class="ml-4">
            <span v-if="channelFlag" class="pre-formatted">{{channel_packages}}</span>
            <span v-if="!channelFlag" class="pre-formatted">{{bundle_packages_list}}</span>
          </div>
        </va-modal>
      </div>
      <div>
       <va-modal
          v-model="isShowBundles"
          size="lg"
          okText=""
          cancelText=""
          class="flex md12"
        >
          <div class="color">
            <va-card color="black" class="card">
              <div class="header">
                <h1>{{ShowBundleName}}</h1>
                <h6>User Count: {{BundleCount}}</h6>
              </div>
              <div class="top">
                <va-button class="model_export" @click="subexportFile()">Export</va-button>
              </div>
            </va-card>
          </div>
          <div class="data_table">
            <va-data-table
              :fields="sub_fields"
              :data="sub_filteredData"
              no-pagination
            >
            </va-data-table>
          </div>
        </va-modal>
      </div>
      <div>
        <va-modal
          v-model="showModal"
          size="large"
          title="Update Bundle"
          okText=" Update"
          cancelText=" Cancel"
          @ok="updateBundle()"
          @cancel="list()"
          no-outside-dismiss
        >
          <div class="top">
            <va-button flat icon="fa fa-times" @click="list()"></va-button>
          </div>
          <form>
            <span class="va-form-label va-form-required-label">Bundle Name</span>
            <va-input
              v-if="isCreate || (isUpdate && !smspack)"
              v-model.trim="bundle_name"
              type="text"
              placeholder="Enter Bundle Name"
              :error="!!bundleNameErrors.length"
              :error-messages="bundleNameErrors"
              @input="checkBundleName(bundle_name)"
            />
            <va-input
              v-if="isUpdate && smspack"
              v-model.trim="bundle_name"
              type="text"
              :disabled= "true"
              placeholder="Enter Bundle Name"
            />
            <div class="row">
              <va-radio-button
                v-if="isUpdate && !customPackFlag"
                option="bundlepackage"
                v-model="bundle_type"
                label="Bundle Package"
                :disabled="true"
              />
              <va-radio-button
                v-if="isUpdate && !customPackFlag"
                option="externalpackage"
                v-model="bundle_type"
                label="External Package"
                :disabled="true"
              />
              <va-radio-button
                v-if="isUpdate && !customPackFlag"
                option="groupedpackage"
                v-model="bundle_type"
                label="Grouped Package"
                :disabled="true"
              />
              <va-radio-button
                v-if="isUpdate && customPackFlag"
                option="custompackage"
                v-model="bundle_type"
                label="Custom Package"
                :disabled="true"
              />
            </div>
            <div v-if="bundle_type == 'bundlepackage'">
              <span class="va-form-label va-form-required-label" >Select Bundle</span>
              <div class="row ml-1">
                <va-radio-button
                  v-if="isUpdate && adminRole"
                  option="ott_pack"
                  v-model="package_type"
                  label="OTT"
                  @input='getpackage(package_type)'
                  :disabled="true"
                />
                <va-radio-button
                  v-if="isUpdate && adminRole"
                  option="iptv_pack"
                  v-model="package_type"
                  label="IPTV"
                  @input='getpackage(package_type)'
                  :disabled="true"
                />
              </div>
            </div>
            <span class="va-form-label va-form-required-label">Bundle Mode</span>
            <va-input
              v-model="bundle_mode"
              type="text"
              :disabled="true"
            />
            <div v-if="bundle_type == 'bundlepackage'">
              <span class="va-form-label va-form-required-label">Package</span>
              <va-select
                v-if="isCreate"
                placeholder="Select Package"
                multiple
                searchable
                v-model="package"
                textBy="package_name"
                @input='getCost(package, "package")'
                :options="packageArr"
                :error="!!packageErrors.length"
                :error-messages="packageErrors"
              />
              <multiselect
                v-if="isUpdate && !smspack"
                v-model.trim="package"
                :options="packageArr"
                placeholder="Select Package"
                searchable
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                track-by="id"
                id="example"
                label="package_name"
                @input='getCost(package, "package")'
              />
              <va-input
                v-if="isUpdate && smspack"
                v-model.trim="package"
                type="text"
                placeholder="Enter Package"
                :disabled="true"
              />

            </div>
            <div v-if="bundle_type == 'custompackage'">
              <span class="va-form-label va-form-required-label">Package</span>
              <va-input
                v-if="isUpdate"
                v-model.trim="custom_package"
                type="text"
                placeholder="Enter Package"
                :disabled="true"
              />
            </div>
            <div v-if="bundle_type == 'externalpackage'">
              <span class="va-form-label va-form-required-label">External Package</span>
              <va-select
                placeholder="Select External Package"
                multiple
                searchable
                v-model="external_package"
                textBy="name"
                :options="externalPackageArr"
                :error="!!externalpackageErrors.length"
                :error-messages="externalpackageErrors"
              />
              <span class="va-form-label va-form-required-label">Package Id</span>
              <va-input
                v-model.trim="external_bundle_package_id"
                type="text"
                placeholder="Enter package id"
                :error="!!externalbundlepackageidErrors.length"
                :error-messages="externalbundlepackageidErrors"
                required="true"
              />
            </div>
            <span v-if="bundle_type == 'bundlepackage'" class="va-form-label va-form-required-label">MoQ</span>
            <va-input
              v-if="bundle_type == 'bundlepackage'"
              v-model="moq"
              type="number"
              placeholder="Enter MoQ"
              :error="!!moqErrors.length"
              :error-messages="moqErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <div v-if="bundle_type == 'groupedpackage'">
              <span>OTT Bundle</span>
              <multiselect
                v-if="isUpdate"
                placeholder="Select Bundle"
                v-model="base_bundle"
                searchable
                multiple="true"
                label="bundle_name"
                id="example"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                track-by="id"
                :options="bundleArr"
              />
              <div v-if="addonbundle_backup && addonbundle_backup.length > 0">
              <span>IPTV Bundle</span>
              <multiselect
                v-if="isUpdate"
                v-model="addonbundle"
                searchable
                placeholder="Select Addon Bundle"
                label="bundle_name"
                track-by="id"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                id="example"
                :multiple="true"
                :options="addonbundleArr"
              />
              </div>
            </div>
            <!-- <u><h1>PRICE LIST</h1></u><br> -->
            <span class="va-form-label va-form-required-label" v-if="one_month_flag">One Month Price</span>
            <va-input
              v-if="one_month_flag"
              v-model="price_one_month"
              type="number"
              placeholder="Enter One Month Price"
              :error="!!priceOneMonthErrors.length"
              :error-messages="priceOneMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label" v-if="three_month_flag">Three Month Price</span>
            <va-input
              v-if="three_month_flag"
              v-model="price_three_month"
              type="number"
              placeholder="Enter Three Month Price"
              :error="!!priceThreeMonthErrors.length"
              :error-messages="priceThreeMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label" v-if="six_month_flag">Six Month Price</span>
            <va-input
              v-if="six_month_flag"
              v-model="price_six_month"
              type="number"
              placeholder="Enter Six Month Price"
              :error="!!priceSixMonthErrors.length"
              :error-messages="priceSixMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label" v-if="twelve_month_flag">Twelve Month Price</span>
            <va-input
              v-if="twelve_month_flag"
              v-model="price_twelve_month"
              type="number"
              placeholder="Enter Twelve Month Price"
              :error="!!priceTwelveMonthErrors.length"
              :error-messages="priceTwelveMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label">Allowed Type</span>
            <fieldset class="row" style="margin-left: 1px;">
              <va-radio-button
                option="only_stb"
                v-model="allowed_device"
                label="Only STB"
              />
              <va-radio-button
                option="only_app"
                v-model="allowed_device"
                label="Only APP"
              />
              <va-radio-button
                option="stb_app"
                v-model="allowed_device"
                label="STB + APP"
              />
            </fieldset>
            <va-checkbox
              v-if="enable_external && (bundle_type != 'externalpackage')  && (bundle_type != 'groupedpackage') "
              v-model="is_external_packages"
              label="External Bundles"
            />
            <span class="va-form-label va-form-required-label" v-if="enable_external && is_external_packages && (bundle_type != 'externalpackage')  && (bundle_type != 'groupedpackage')">External Packages</span>
            <va-input
              v-if="enable_external && is_external_packages && (bundle_type != 'externalpackage')  && (bundle_type != 'groupedpackage')"
              v-model="custom_package_external"
              :disabled = "true"
            />
            <!-- <va-checkbox
              v-if="bundle_type != 'groupedpackage'"
              v-model="add_on"
              @input = 'disable("add_on")'
              label="Add on"
            /> -->
            <!-- <va-checkbox
              v-if="bundle_type != 'groupedpackage'"
              v-model="iptv"
              @input = 'disable("iptv")'
              label="IPTV"
            /> -->
            <va-checkbox
              v-if="isPreactive && !this.iptv && !this.add_on && bundle_type != 'externalpackage'"
              v-model="pre_active"
              label="Pre-activate"
              flag="false"
            />
            <div v-if="pre_active && !this.iptv && !this.add_on && bundle_type!= 'externalpackage'" >
              <span  class="va-form-label va-form-required-label" flag=true>Cost Price (Exclusive of GST)</span>
              <va-input
                v-if='pre_active && !iptv'
                v-model="cost_price"
                placeholder="Enter cost price"
                type="textbox"
                oninput="event.target.value = event.target.value.replace(/[^0-9\.]/g,'');"
                :error="!!CostPriceErrors.length"
                :error-messages="CostPriceErrors"
              />
            </div>
            <template v-for="(value, key) in img_arr">
              <img class="image" :src=value>
            </template>
          </form>
        </va-modal>
      </div>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Bundle Name</span>
            <va-input
              v-model.trim="bundle_name"
              type="text"
              placeholder="Enter Bundle Name"
              :error="!!bundleNameErrors.length"
              :error-messages="bundleNameErrors"
              @input="checkBundleName(bundle_name)"
            />
            <span class="va-form-label va-form-required-label">Bundle Type</span>
            <div class="row ml-1">
              <va-radio-button
                v-if="isCreate && adminRole"
                option="bundlepackage"
                v-model="bundle_type"
                label="Bundle Package"
              />
              <va-radio-button
                v-if="isCreate && adminRole"
                option="externalpackage"
                v-model="bundle_type"
                label="External Package"
              />
              <va-radio-button
                v-if="isCreate && adminRole"
                option="groupedpackage"
                v-model="bundle_type"
                label="Grouped Package"
              />
              <va-radio-button
                v-if="isCreate && operatorRole"
                option="custompackage"
                v-model="bundle_type"
                label="Custom Package"
              />
            </div>
            <div v-if="bundle_type == 'bundlepackage'">
              <span class="va-form-label va-form-required-label" >Select Bundle</span>
              <div class="row ml-1">
                <va-radio-button
                  v-if="isCreate && adminRole"
                  option="ott_pack"
                  v-model="package_type"
                  label="OTT"
                  @input='getpackage(package_type)'
                />
                <va-radio-button
                  v-if="isCreate && adminRole"
                  option="iptv_pack"
                  v-model="package_type"
                  label="IPTV"
                  @input='getpackage(package_type)'
                />
              </div>
            </div>
            <br>
            <span class="va-form-label va-form-required-label">Bundle Mode</span>
            <va-select
              placeholder="Select Bundle Mode"
              searchable
              v-model="bundle_mode"
              textBy="bundle_mode"
              @input='getBundlePrice(bundle_mode)'
              :options="bundleModeArr"
              :error="!!bundleModeErrors.length"
              :error-messages="bundleModeErrors"
            />
            <div v-if="bundle_type == 'custompackage'">
              <span class="va-form-label va-form-required-label">Custom Package</span>
              <va-select
                v-if="isCreate"
                placeholder="Select Custom Package"
                multiple
                searchable
                @input='getCost(custom_package,"custompackage")'
                v-model="custom_package"
                textBy="bundle_name"
                :options="customPackageArr"
                :error="!!customPackageErrors.length"
                :error-messages="customPackageErrors"
              />
              <va-input
                v-if="isUpdate"
                v-model.trim="package"
                type="text"
                placeholder="Enter Package"
                :disabled="true"
              />
            </div>
            <div v-if="bundle_type == 'bundlepackage'">
              <span class="va-form-label va-form-required-label">Packages</span>
              <multiselect
                v-if="isCreate"
                placeholder="Select Package"
                v-model="package"
                searchable
                multiple="true"
                label="package_name"
                id="example"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                track-by="id"
                :options="packageArr"
                @input='getCost(package, "package")'
              />
              <div
                v-if="packageErrors.length > 0"
                class="errors">
              </div>
              <va-input
                v-if="isUpdate"
                v-model.trim="package"
                type="text"
                placeholder="Enter Package"
                :disabled="true"
              />
            </div>
            <div v-if="bundle_type == 'externalpackage'">
              <span class="va-form-label va-form-required-label">External Package</span>
              <va-select
                placeholder="Select External Package"
                multiple
                searchable
                v-model="external_package"
                textBy="name"
                :options="externalPackageArr"
                :error="!!externalpackageErrors.length"
                :error-messages="externalpackageErrors"
              />
              <span class="va-form-label va-form-required-label">Package Id</span>
              <va-input
                v-model.trim="external_bundle_package_id"
                type="text"
                placeholder="Enter package id"
                :error="!!externalbundlepackageidErrors.length"
                :error-messages="externalbundlepackageidErrors"
                required="true"
              />
            </div>
            <span v-if="bundle_type == 'bundlepackage'" class="va-form-label va-form-required-label">MoQ</span>
            <va-input
              v-if="bundle_type == 'bundlepackage'"
              v-model="moq"
              type="number"
              placeholder="Enter MoQ"
              :error="!!moqErrors.length"
              :error-messages="moqErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <div v-if="bundle_type == 'groupedpackage'">
              <span class="va-form-required-label va-form-label">OTT Bundle</span>
              <multiselect
                placeholder="Select Bundle"
                v-model="base_bundle"
                searchable
                multiple="true"
                label="bundle_name"
                id="example"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                track-by="id"
                :options="bundleArr"
              />
              <span class="va-form-label">Add on Bundle</span>
              <multiselect
                v-model="addonbundle"
                placeholder="Select Addon Bundle"
                label="bundle_name"
                track-by="id"
                searchable
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                id="example"
                :multiple="true"
                :options="addonbundleArr"
              />
            </div>
            <!-- <u><h1>PRICE LIST</h1></u><br> -->
            <span class="va-form-label va-form-required-label" v-if="one_month_flag">One Month Price</span>
            <va-input
              v-if="one_month_flag"
              v-model="price_one_month"
              type="number"
              placeholder="Enter One Month Price"
              :error="!!priceOneMonthErrors.length"
              :error-messages="priceOneMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label" v-if="three_month_flag">Three Month Price</span>
            <va-input
              v-if="three_month_flag"
              v-model="price_three_month"
              type="number"
              placeholder="Enter Three Month Price"
              :error="!!priceThreeMonthErrors.length"
              :error-messages="priceThreeMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label" v-if="six_month_flag">Six Month Price</span>
            <va-input
              v-if="six_month_flag"
              v-model="price_six_month"
              type="number"
              placeholder="Enter Six Month Price"
              :error="!!priceSixMonthErrors.length"
              :error-messages="priceSixMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label" v-if="twelve_month_flag">Twelve Month Price</span>
            <va-input
              v-if="twelve_month_flag"
              v-model="price_twelve_month"
              type="number"
              placeholder="Enter Twelve Month Price"
              :error="!!priceTwelveMonthErrors.length"
              :error-messages="priceTwelveMonthErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'').replace(/^0*/, '');"
            />
            <span class="va-form-label va-form-required-label">Allowed Type</span>
            <fieldset class="row" style="margin-left: 1px;">
              <va-radio-button
                option="only_stb"
                v-model="allowed_device"
                label="Only STB"
              />
              <va-radio-button
                option="only_app"
                v-model="allowed_device"
                label="Only APP"
              />
              <va-radio-button
                option="stb_app"
                v-model="allowed_device"
                label="STB + APP"
              />
            </fieldset>
            <!-- <va-checkbox
              v-if="!operatorRole && bundle_type != 'groupedpackage'"
              v-model="add_on"
              @input = 'disable("add_on")'
              label="Add on"
            />
            <va-checkbox
              v-if="bundle_type != 'groupedpackage'"
              v-model="iptv"
              @input = 'disable("iptv")'
              label="IPTV"
            /> -->
            <va-checkbox
              v-if="isPreactive  && !this.iptv && !this.add_on && bundle_type!= 'externalpackage'"
              v-model="pre_active"
              label="Pre-activate"
              flag="false"
            />
            <div v-if="pre_active  && !this.iptv && !this.add_on && bundle_type!= 'externalpackage'">
              <span class="va-form-label va-form-required-label" flag=true>Cost Price (Exclusive of GST)</span>
              <va-input
                v-if='pre_active'
                v-model="cost_price"
                type="textbox"
                placeholder="Enter cost price"
                oninput="event.target.value = event.target.value.replace(/[^0-9\.]/g,'')"
              />
            </div>
            <va-checkbox
              v-if="enable_external && (bundle_type != 'externalpackage' && bundle_type != 'groupedpackage')"
              v-model="is_external_packages"
              label="External Packages"
            />
            <span class="va-form-label va-form-required-label" v-if="enable_external && is_external_packages && (bundle_type != 'externalpackage' && bundle_type != 'groupedpackage')">External Packages</span>
          <!--  <va-select
              v-if="enable_external && is_external_packages && (bundle_type != 'externalpackage' && bundle_type != 'groupedpackage')"
              placeholder="Select External Bundle"
              searchable
              multiple
              v-model="custom_external_package"
              textBy="bundle_name"
              :options="customExternalPackArr"
              :error="!!customExternalPackErrors.length"
              :error-messages="customExternalPackErrors"
            />-->
            <multiselect
                  v-if="enable_external && is_external_packages && (bundle_type != 'externalpackage' && bundle_type != 'groupedpackage')"
                  placeholder="Select External Bundle"
                  v-model="custom_external_package"
                  searchable
                  multiple="true"
                  label="bundle_name"
                  id="example"
                 :close-on-select="false"
                 :clear-on-select="true"
                 :preserve-search="true"
                 :hide-selected="false"
                 :taggable="false"
                  track-by="id"
                 :options="customExternalPackArr"
             />
          </form>
          <div class="d-flex justify--end mt-3">
            <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
            <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createBundle()">Create</va-button>
          </div>
        </div>
      </va-card>
    </div>
  </div>
  <!-- <div v-else-if="showMsg">
    <va-card><center><h1>{{message}}</h1></center></va-card>
  </div> -->
  <div v-else-if="(bkdata.length == 0) && loadFlag">
    <va-card>
      <template slot="actions" >
        <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <center><h1>Your Bundle is empty. Please add bundle.</h1></center>
    </va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import { exportToFile } from '../../i18n/exportFile.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)
export default {
  name: 'bundle',
  beforeCreate () {
    var vm = this
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + '/external_app').then(re => {
      loader.hide()
      this.externalPackageArr = re.body
      this.$http.get(config.menu.host + '/provider').then(resp => {
        this.iptvProvider = []; this.ottProvider = []
          resp.body.map(function(providerDatas){
          if (providerDatas.iptv) {
            vm.iptvProvider.push(providerDatas.provider_id)
          } else {
            vm.ottProvider.push(providerDatas.provider_id)
          }
          })
      })
      this.$http.get(config.menu.host + '/package').then(resp => {
        this.packageArr = resp.body.package
        this.packageArrBk=this.packageArr
        this.packageArr.map(function (arg, i) { arg.id = i + 1 })
        this.getAllBundle()
      })
    }, error => {
      loader.hide()
      if (error && error.body) {
        Vue.notify({ text: error.body, type: 'error' })
      }
    })
    var grouped_arr = []; var grouped_add_on_arr = []
    this.$http.get(config.menu.host + '/bundle').then(res => {
      res.body.map(function (item, i) {
        item.id = i + 1
        item.grouped_bundle_id = item.bundle_id
        if (!item.add_on && item.bundle_type != 'resellerpackage' && item.bundle_type != 'groupedpackage' && !item.bundle_name.includes('NCF') && item.bundlepackages.length > 0) {
          grouped_arr.push(item)
        } else if (item.add_on && item.bundle_type != 'resellerpackage' && item.bundle_type != 'groupedpackage' && !item.bundle_name.includes('NCF') && item.bundlepackages.length > 0) {
          grouped_add_on_arr.push(item)
        }
      })
      this.bundleArrList = grouped_arr
      this.addonbundleArrList = grouped_add_on_arr
    })
  },
  data () {
    var role = Vue.$cookies.get('userRole')
    var reseller_op = Vue.$cookies.get('enable_reseller_bundle_creation')
    var dynamic_tab = []
    if (reseller_op == 1 && (role == 'OPERATOR' || role == 'HEPI_OPERATOR')) {
      dynamic_tab = ['Reseller Custom Bundle']
    } else {
      dynamic_tab = ['OTT Bundle']
    }
    return {
      term: null,
        selectOtns: {
        icon: 'entypo entypo-check',
        click: this.selectAll,
        value: 'Select All',
        color: 'primary',
      },
      ifCheckboxValue:false,
      selectFlag:true,
      perPage: '10',
      bundleflag: false,
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      adminRole: false,
      operatorRole: false,
      showMsg: false,
      showRemoveModal: false,
      add_on: false,
      pre_active: false,
      isPreactive: false,
      cost_price: 0,
      iptv: false,
      bundle_name: '',
      allow_type: '',
      moq: 0,
      filterType: '',
      message: '',
      price_one_month: 0,
      price_six_month: 0,
      price_three_month: 0,
      package: [],
      price_twelve_month: 0,
      title: '',
      bundleData: [],
      bundleDataBackup: [],
      moqErrors: [],
      external_package: [],
      externalPackageArr: [],
      externalpackageErrors: [],
      bundleNameErrors: [],
      allowType: [],
      packageArr: [],
      packageArrBk:[],
      packageErrors: [],
      priceOneMonthErrors: [],
      priceSixMonthErrors: [],
      priceThreeMonthErrors: [],
      priceTwelveMonthErrors: [],
      costPriceError: [],
      bkdata: [],
      bundleModeArr: [{ id: 1, bundle_mode: 'One Month' }, { id: 2, bundle_mode: 'Three Month' }, { id: 3, bundle_mode: 'Six Month' }, { id: 4, bundle_mode: 'Twelve Month' }],
      bundleModeErrors: [],
      bundle_mode: '',
      bundle_type: 'bundlepackage',
      one_month_flag: false,
      three_month_flag: false,
      six_month_flag: false,
      twelve_month_flag: false,
      mode: {
        'One Month': 'one_month_flag',
        'Three Month': 'three_month_flag',
        'Six Month': 'six_month_flag',
        'Twelve Month': 'twelve_month_flag',
      },
      cost_mode: {
        'One Month': 'price_one_month',
        'Three Month': 'price_three_month',
        'Six Month': 'price_six_month',
        'Twelve Month': 'price_twelve_month',
      },
      showModal: false,
      img_arr: [],
      customPackageErrors: [],
      customPackageArr: [],
      custom_package: [],
      ottbundle: [],
      ottarr: [],
      custombundle: [],
      bundle_cost: 0,
      cost_price: 0,
      customPackFlag: false,
      tabTitles: dynamic_tab,
      tabValue: 0,
      enable_external: false,
      is_external_packages: false,
      custom_external_package: [],
      customExternalPackErrors: [],
      customExternalPackArr: [],
      custom_package_external: '',
      allowed_device: '',
      package_type: '',
      base_bundle: '',
      bundleArr: [],
      addonbundle: '',
      addonbundleArr: [],
      bundleArrList: [],
      addonbundleArrList: [],
      loadFlag: false,
      isShowPackages: false,
      channel_packages: '',
      bundle_packages_list: '',
      channelFlag: false,
      pre_activation_bundles: [],
      pre_activation_flag: false,
      isShowBundles: false,
      ShowBundleName: '',
      BundleCount: '',
      subBundleInformation: [],
      mode_obj: {
        'One Month': 'price_one_month',
        'Three Month': 'price_three_month',
        'Six Month': 'price_six_month',
        'Twelve Month': 'price_twelve_month',
      },
      externalbundlepackageidErrors: [],
      external_bundle_package_id: '',
      smspack: false,
      CostPriceErrors: [],
      filterTypeArr: [{id : 1, name : 'OTT' }, {id : 2, name : 'IPTV'}],
    }
  },
  watch: {
    tabValue () {
      var vm=this
      if (this.tabValue == 0) {
        vm.bundleflag= true
        vm.selectOtns = {
          icon: 'entypo entypo-check',
          click: vm.selectAll,
          value: 'Select All',
          color: 'primary',
        }
        this.bundleData.map(function (bundlesItem) {
          bundlesItem.checkbox_value= false
        })
        this.ifCheckboxValue = false
          this.getOTTBundle()
      } else if (this.tabValue == 1) {
          vm.bundleflag= true
          vm.selectOtns = {
            icon: 'entypo entypo-check',
            click: vm.selectAll,
            value: 'Select All',
            color: 'primary',
          }
          this.bundleData.map(function (bundlesItem) {
            bundlesItem.checkbox_value= false
          })
          this.ifCheckboxValue = false
          this.getCustomBundle()
      } else if (this.tabValue == 2) {
          vm.bundleflag= true
          vm.selectOtns = {
            icon: 'entypo entypo-check',
            click: vm.selectAll,
            value: 'Select All',
            color: 'primary',
          }
          this.bundleData.map(function (bundlesItem) {
            bundlesItem.checkbox_value= false
          })
          this.ifCheckboxValue = false
          this.getResellerCustomBundle()
        }
    },
    pre_active (val) {
      if (!this.pre_active) {
        this.cost_price = 0
      }
    },
  },
  computed: {
    formReady () {
      return !this.bundleNameErrors.length && !this.bundleModeErrors.length
    },
    sub_fields () {
      return [
        {
          name: 'sno',
          title: 'S.no',
          width: '5%'
        },
        {
          name: 'name',
          title: 'Subscriber Name',
          width: '30%'
        }, {
          name: 'mobile',
          title: 'Mobile Number'
        }, {
          name: 'serial_no',
          title: 'Serial No'
        }, {
          name: 'mac_address',
          title: 'Mac Address'
        }
      ]
    },
    sub_filteredData () {
      return this.subBundleInformation
    },
    fields () {
      var role = Vue.$cookies.get('userRole')
      if (this.tabTitles == 'Reseller Custom Bundle' && (role == 'OPERATOR' || role == 'HEPI_OPERATOR')) {
        return [
          {
            name: '__slot:checkbox',
          },
          {
            name: 'sno',
            title: 'S.NO',
            width: '6%',
          },
          {
            name: 'bundle_name',
            title: 'Bundle Name',
          },
          {
            name: 'bundle_mode',
            title: 'Mode',
          },
          {
            name: 'list_bundle_pack',
            title: 'Bundle Packages',
          },
          {
            name: 'seller_cost',
            title: 'Bundle Cost',
          },
          {
            name: 'allow_type',
            title: 'Allowed Device',
          },
          {
            name: '__slot:iptv',
            title: 'Type',
          },
          {
            name: '__slot:packages',
            title: 'Channel Packages',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      } else if (this.tabValue == 2 && role == 'ADMIN') {
        return [
          {
            name: '__slot:checkbox',
          },
          {
            name: 'sno',
            title: 'S.NO',
            width: '6%',
          },
          {
            name: 'bundle_name',
            title: 'Bundle Name',
          },
          {
            name: 'bundle_mode',
            title: 'Mode',
          },
          {
            name: 'ott_price',
            title: 'OTT Price',
          },
          {
            name: 'recommend_cost',
            title: 'Recommend Cost',
          },
          {
            name: 'seller_cost',
            title: 'Bundle Cost',
          },
          {
            name: 'allow_type',
            title: 'Allowed Device',
          },
          {
            name: '__slot:iptv',
            title: 'Type',
          },
          {
            name: '__slot:packages',
            title: 'Channel Packages',
          },
          {
            name: '__slot:ListBp',
            title: 'Bundle Packages',
          },
          {
            name: '__slot:information',
            title: 'Active Users',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      } else {
        return [
          {
            name: '__slot:checkbox',
          },
          {
            name: 'sno',
            title: 'S.NO',
            width: '6%',
          },
          {
            name: 'bundle_name',
            title: 'Bundle Name',
          },
          {
            name: 'moq',
            title: 'MoQ',
          },
          {
            name: 'bundle_mode',
            title: 'Mode',
          },
          {
            name: 'list_extern_pack',
            title: 'Apps',
          },
          {
            name: 'total_bundle_cost',
            title: 'Bundle Cost',
          },
          {
            name: 'allow_type',
            title: 'Allowed Device',
          },
          {
            name: '__slot:iptv',
            title: 'Type',
          },
          {
            name: '__slot:add_on',
            title: 'Add On',
          },
          {
            name: '__slot:packages',
            title: 'Channel Packages',
          },
          {
            name: '__slot:ListBp',
            title: 'Bundle Packages',
          },
          {
            name: '__slot:information',
            title: 'Active Users',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      }
    },
    filteredData () {
      var searchedData = search(this.term, this.bundleData)
      this.selectFlag = (searchedData.length == 0 ) ? false : true
      return searchedData
    },
  },
  methods: {
    select (data) {
      var iteration= 0
      this.ifCheckboxValue = true
      this.bundleflag=false
      this.bundleData.map(function(data){
          data.rowPosition=iteration++
      })
      this.bundleData[data.rowPosition].checkbox_value = !this.bundleData[data.rowPosition].checkbox_value
      const bundle_array_selected = this.bundleData.filter(data => data.checkbox_value)
      if (bundle_array_selected.length == 0) {
        this.ifCheckboxValue = false
        this.selectOtns = {
          icon: 'entypo entypo-check',
          click: this.selectAll,
          value: 'Select All',
          color: 'primary',
        }
        this.getAllBundle()
      } else {
        this.bundleflag=true
        this.selectOtns = {
          icon: 'entypo entypo-cancel',
          click: this.deSelectAll,
          value: 'De Select All',
          color: 'danger',
        }
      }
    },
    selectAll () {
        this.bundleData.map(function (bundlesItem) {
          bundlesItem.checkbox_value = true
        })
        this.bundleflag= true
        this.selectOtns = {
          icon: 'entypo entypo-cancel',
          click: this.deSelectAll,
          value: 'De Select All',
          color: 'danger',
        }
        this.ifCheckboxValue = true
    },
    deSelectAll () {
      this.bundleData.map(function (bundlesItem) {
        bundlesItem.checkbox_value = false
      })
      this.bundleflag= false
      this.selectOtns = {
        icon: 'entypo entypo-check',
        click: this.selectAll,
        value: 'Select All',
        color: 'primary',
      }
      this.ifCheckboxValue = false
      this.getAllBundle()
    },
    exportFile(){
      var selectedBundleArr = []
      this.bundleData.map(function (bundleItem) {
        if (bundleItem.checkbox_value) {
          selectedBundleArr.push(bundleItem)
          }
        })
        var bundle_id_arr=[];
        selectedBundleArr.map(function(item){
          bundle_id_arr.push(item.bundle_id)
        })
          var rowKeys = {
             bundle_name : 'Bundle Name',
             channel_name : 'Channels name '
          }
          var bundlepayload={
              bundle_id : bundle_id_arr
          }
        this.$http.post(config.menu.host + '/bundle/export',bundlepayload).then(resp => {
          exportToFile(resp.body, rowKeys, 'Bundles', 'CSV')
      })
        this.deSelectAll ()
    },
    getRole () {
      return Vue.$cookies.get('userRole')
    },
    disable(newValue){
      if(newValue == 'add_on' || newValue == 'iptv'){
        this.pre_active = false
      }
    },
    checkBundleName (bundle_name){
      if(bundle_name.includes('NCF')){
        this.isPreactive = false
        this.pre_active = false
      }else if((!this.iptv) && (this.bundle_type != 'externalpackage')){
        if(this.isCreate && (this.bundle_mode.bundle_mode == 'One Month')){
          this.isPreactive = true
          this.pre_active ? this.pre_active : false
        }else if(this.isUpdate && (this.bundle_mode == 'One Month')){
          this.isPreactive = true
          this.pre_active = false
        }
      }
    },
    replaceCommaLine (data) {
      let dataToArray = data.split(',')
      dataToArray = dataToArray.map(function (item, i) {
        var datas = (i + 1) + '.  ' + item.trim()
        return datas
      })
      return dataToArray.join('\n')
    },
    guid () {
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    },
    showchannellist (channel_data) {
      this.isShowPackages = true
      this.channelFlag = true
      const availableData = channel_data.channel
      this.channel_packages = this.replaceCommaLine(availableData)
    },
    showbplist (package_data) {
      this.isShowPackages = true
      this.channelFlag = false
      const availableData = package_data.list_bundle_pack
      this.bundle_packages_list = this.replaceCommaLine(availableData)
    },
    showinformation (data) {
       const loader = Vue.$loading.show({ width: 40, height: 40 })
       this.$http.get(config.menu.host + '/bundle/subscription/information/data/'+data.bundle_id).then(resp => {
        loader.hide();
        this.isShowBundles = true
        this.ShowBundleName = data.bundle_name
        this.subBundleInformation = resp.body.map((data,index)=>{
          data.sno=index+1+'.';
          return data;
        });
        this.BundleCount = resp.body.length
      }, err => {
          loader.hide();
          if(err && err.body) return Vue.notify({ text: err.body, type: 'error'});
      })
    },
    subexportFile () {
      var rowKeys = {
        name: 'Subscriber Name',
        mobile: 'Mobile Number',
        serial_no: 'Serial Number',
        mac_address: 'Mac Address',
      }
      exportToFile(this.subBundleInformation, rowKeys, 'Active Subscribers', 'CSV')
    },
    getAllBundle () {
      this.bundleflag = true
      var role = this.$cookies.get('userRole')
      if (role === 'ADMIN') {
        this.enable_external = true
        this.adminRole = true
        this.operatorRole = false
      } else if (role === 'OPERATOR' || role === 'HEPI_OPERATOR') {
        this.$http.get(config.menu.host + '/operator_setting').then(oper => {
          this.enable_external = !!oper.body[0].external_apps
          this.operatorRole = (oper.body[0].enable_bundle_creation && (!oper.body[0].enable_reseller_bundle_creation || oper.body[0].enable_reseller_bundle_creation == null))
          this.bundle_type = 'custompackage'
          this.pre_activation_flag = oper.body[0].pre_activation_flag
          this.pre_activation_bundles = oper.body[0].pre_activation
          this.selectOtns = {
              icon: 'entypo entypo-check',
              click: this.selectAll,
              value: 'Select All',
              color: 'primary',
            }
            this.ifCheckboxValue = false
        })
      } else {
        this.adminRole = false
        this.operatorRole = false
      }
      var vm = this
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/bundle').then(response => {
          this.$http.get(config.menu.host + '/bundle/global/new/pre_active').then(global_preactive_bundle => {
          this.$http.get(config.menu.host + '/bundle/external/bundle').then(responses => {
              var global_preactive = global_preactive_bundle.body
          if ((role === 'OPERATOR' || role === 'HEPI_OPERATOR') && this.pre_activation_flag) {
                response.body = [...global_preactive,...response.body]
             }
        loader.hide()
        this.loadFlag = (response.body.length > 0)
        this.bkdata = (response.body.length > 0) ? JSON.stringify(response.body) : []
        if (response.body.msg) {
          this.isshowgrid = false
          this.isshowForm = false
          this.showMsg = true
          this.message = response.body.msg
        } else {
          this.ottbundle = [], this.custombundle = [], this.externalbundlelist = [], this.reseller_custom_bundle = [] , this.ottarr =[]
          var bundle_obj = {}
          let ott_index = 1
          responses.body.map(function (argument) {
            if (argument.bundle_type == 'externalpackage'){
              var pack = '';
              if(argument.bundle_external_packages && argument.bundle_external_packages.length >0) {
              argument.bundle_external_packages.map(function (thing) {
                if (pack === '') {
                  pack = thing.name
                } else {
                  pack = pack + ',' + thing.name
                }
              })
              }
              bundle_obj[argument.bundle_id] = pack
            }
          })
          response.body.map(function (item) {
            var pack = ''; var custompack = ''; var extern_pack = ''; var reseller_pack = ''; var grouped_arr = []; var group_pack = ''; var allow_type = ''
            if (item.allowed_device == 'stb_app') {
              item.allow_type = 'STB+APP'
            } else if (item.allowed_device == 'only_app') {
              item.allow_type = 'APP'
            } else if (item.allowed_device == 'only_stb') {
              item.allow_type = 'STB'
            }
            if(item.iptv){
              item.bundle_mode = (item.bundle_mode) ? item.bundle_mode : "One Month"
              item.total_bundle_cost = item[vm.mode_obj[item.bundle_mode]]
            }else if(!item.iptv){
              item.total_bundle_cost = item[vm.mode_obj[item.bundle_mode]]
            }
            if ((item.bundle_type == 'resellerpackage') && (role == 'OPERATOR' || role == 'HEPI_OPERATOR')) {
              item.total_bundle_cost = item.seller_cost
            } else if ((item.bundle_type == 'bundlepackage') && (role == 'OPERATOR' || role == 'HEPI_OPERATOR')) {
              item.seller_cost = item.total_bundle_cost
            } else if ((item.bundle_type == 'groupedpackage') && (role == 'OPERATOR' || role == 'HEPI_OPERATOR')) {
              item.seller_cost = item.total_bundle_cost
            }
            // item.id = index++
            // item.sno = index
            item.is_existing = true
            item.checkbox_value = false
            if (item.bundle_type === 'custompackage') {
              item.bundle_custom_packages.map(function (thing) {
                if (custompack === '') {
                  custompack = thing.bundle_name
                } else {
                  custompack = custompack + ', ' + thing.bundle_name
                }
              })
              item.list_bundle_pack = custompack
              if(!vm.custombundle.some(function(op) {return (item.bundle_id == op.bundle_id)})){
                vm.custombundle.push(item)
              }
              item.bundle_custom_external_packages.map(function (things) {
                if (extern_pack === '') {
                  extern_pack = things.bundle_name
                } else {
                  extern_pack = extern_pack + ', ' + things.bundle_name
                }
              })
              item.list_extern_pack = extern_pack
            } else if (item.bundle_type == 'bundlepackage') {
                  item.bundlepackages.map(function (thing) {
                if (pack === '') {
                  pack = thing.package_name
                } else {
                  pack = pack + ', ' + thing.package_name
                }
              })
              item.list_bundle_pack = pack
              if(!vm.ottbundle.some(function(op) {
                return (item.bundle_id == op.bundle_id)})
                ){
                vm.ottbundle.push(item)
              }
              vm.ottarr.push(item.bundle_id)

              item.bundle_custom_external_packages.map(function (things) {
                var bundle_id = things.custom_external_bundle_id
                // if (extern_pack === '') {
                //   extern_pack = things.bundle_name
                // } else {
                //   extern_pack = extern_pack + ', ' + things.bundle_name
                // }
                  if (extern_pack === '') {
                    extern_pack = bundle_obj[bundle_id]
                  } else {
                    extern_pack = extern_pack + ', ' + bundle_obj[bundle_id]
                  }
              })
              item.list_extern_pack = extern_pack
            } else if (item.bundle_type == 'groupedpackage') {
              item.bundlepackages.map(function (item_pack) {
                if (group_pack === '') {
                  group_pack = item_pack.package_name
                } else {
                  group_pack = group_pack + ', ' + item_pack.package_name
                }
              })
              item.list_bundle_pack = group_pack
               if(!vm.ottbundle.some(function(op) {
                return (item.bundle_id == op.bundle_id)})
                ){
                vm.ottbundle.push(item)
              }
              vm.ottarr.push(item.bundle_id)
                item.bundle_custom_external_packages.map(function (things) {
                if (extern_pack === '') {
                  extern_pack = things.bundle_name
                } else {
                  extern_pack = extern_pack + ', ' + things.bundle_name
                }
              })
                item.list_extern_pack = extern_pack
            } else if (item.bundle_type == 'resellerpackage') {
              item.bundle_reseller_custom_packages.map(function (thing) {
                if (pack === '') {
                  pack = thing.bundle_name
                } else {
                  pack = pack + ', ' + thing.bundle_name
                }
              })
              item.list_bundle_pack = pack
              vm.reseller_custom_bundle.push(item)
              if (role !== 'ADMIN') {
                if(!vm.ottbundle.some(function(op) {
                return (item.bundle_id == op.bundle_id)})
                ){
                vm.ottbundle.push(item)
              }
                vm.ottarr.push(item.bundle_id)

              }
              item.bundle_custom_external_packages.map(function (things) {
                if (extern_pack === '') {
                  extern_pack = things.bundle_name
                } else {
                  extern_pack = extern_pack + ', ' + things.bundle_name
                }
              })
              item.list_extern_pack = extern_pack
            } else {
              item.bundle_external_packages.map(function (thing) {
                if (pack === '') {
                  pack = thing.name
                } else {
                  pack = pack + ',' + thing.name
                }
              })
              item.list_bundle_pack = pack
              vm.externalbundlelist.push(item)
                if(!vm.ottbundle.some(function(op) {
                return (item.bundle_id == op.bundle_id)})
                ){
                vm.ottbundle.push(item)
              }
              vm.ottarr.push(item.bundle_id)

            }
            return item
          })
          vm.ottbundle.map(data => { data.sno = ott_index++ });
          this.bundleData = vm.ottbundle
          this.bundleDataBackup = vm.ottbundle
          if ((role === 'OPERATOR' || role === 'HEPI_OPERATOR') && this.pre_activation_flag) {
            var current_date = new Date();
            this.pre_activation_bundles.map(function (pre_bundle) {
              vm.bundleData.map(function (bundles) {
                if ((pre_bundle.bundle_name.bundle_id === bundles.bundle_id) && !pre_bundle.removed_flag && (pre_bundle.free_count > pre_bundle.used_count) && (new Date(pre_bundle.end_date).getTime() > current_date.getTime())&& (new Date(pre_bundle.start_date).getTime() <= current_date.getTime() )) {
                  bundles.bundle_mode = pre_bundle.mode.id
                  if (Vue.$cookies.get('enable_reseller_bundle_creation') == 1) {
                    bundles.seller_cost = 0
                  } else {
                    bundles.total_bundle_cost = 0
                  }
                }
              })
            })
          }
          if (this.adminRole || this.operatorRole) {
            if (this.tabTitles.indexOf('Custom Bundle') <= -1) {
              this.tabTitles.push('Custom Bundle')
            }
          }
          if (this.adminRole) {
            if (this.tabTitles.indexOf('Reseller Custom Bundle') <= -1) {
              this.tabTitles.push('Reseller Custom Bundle')
            }
          }
          this.isshowgrid = true
          if(typeof(this.filterType)=="object"){
            this.getTypeFilter(this.filterType)
          }
        }

      })
      })
      })
    },
    getTypeFilter (data) {
      if(this.selectOtns.value == 'De Select All'){
        if(this.bundleData && this.bundleData.length>0){
          this.bundleData.map(function (bundlesItem) {
            bundlesItem.checkbox_value = false
          })
        }
        this.bundleflag= true
        this.selectOtns = {
          icon: 'entypo entypo-check',
          click: this.selectAll,
          value: 'Select All',
          color: 'primary',
        }
        this.ifCheckboxValue = false
      }
      this.bundleData = this.bundleDataBackup
      if(typeof(data)=="object"){
        var selectedType = data.name
        var bundleTypeArr = []
        this.bundleData.map(function (bundleType) {
          if((selectedType == 'IPTV') && bundleType.iptv ) {
            bundleTypeArr.push(bundleType)
          } else if ((selectedType == 'OTT') && !bundleType.iptv) {
            bundleTypeArr.push(bundleType)
          }
        })
        this.bundleData = bundleTypeArr
      }
    },
    getCost (data, label) {
      var vm = this
      var packCost = 0
      data.map(function (point) {
        if (label === 'custompackage') {
          var price_mode = vm.cost_mode[point.bundle_mode]
          packCost = packCost + point[price_mode]
        } else {
          packCost = packCost + point.amount
        }
      })
      this.bundle_cost = packCost
    },
    getpackage (data){
      if(data == "iptv_pack"){
        this.isPreactive = false
        this.pre_active = false
      } else {
        if(this.bundle_mode && typeof(this.bundle_mode) == "object"){
        this.getBundlePrice(this.bundle_mode)
      }
      }
      var vm = this
      if (this.isCreate) {
        vm.package = []
      }
      var ottPackage = []
      var iptvPackage = []
      this.packageArrBk.map(function (packageType, i){
        var package_provide_id = packageType.provider_id
        if ( data == "ott_pack") {
          if (vm.ottProvider.indexOf(package_provide_id) != -1) {
            ottPackage.push(packageType)
          }
        }else if(data == "iptv_pack") {
          if ( vm.iptvProvider.indexOf(package_provide_id) != -1) {
            iptvPackage.push(packageType)
          }
        }
        if(i +1 ==vm.packageArrBk.length){
            data == "iptv_pack"?vm.packageArr=iptvPackage:vm.packageArr=ottPackage
            vm.packageArr.map(function (arg, i) { arg.id = i + 1 })
        }
      })
    },
    getBundlePrice (mode) {
      if (mode.bundle_mode == 'One Month' && this.package_type == "ott_pack" ) {
        this.isPreactive = true
      } else {
        this.isPreactive = false
        this.pre_active = false
      }
      this.one_month_flag = false
      this.three_month_flag = false
      this.six_month_flag = false
      this.twelve_month_flag = false
      if(this.isCreate){
         this.price_one_month = 0
               this.price_three_month = 0
         this.price_six_month = 0
         this.price_twelve_month = 0
      }
      var mode_key = this.mode[mode.bundle_mode]
      if (mode == '' && this.bundle_type == 'groupedpackage') {
        this.addonbundle = ''
        this.base_bundle = ''
      }
      this[mode_key] = true
      if ((this.bundle_type === 'custompackage') || (this.bundle_type === 'bundlepackage') || (this.bundle_type == 'groupedpackage')) {
        this.customPackageArr = []
        this.customPackageArr = this.ottbundle.filter(function (data) {
          data.custom_bundle_id = data.bundle_id
          return data.bundle_mode == mode.bundle_mode
        })
        this.customExternalPackArr = this.externalbundlelist.filter(function (datas, i) {
          datas.custom_external_bundle_id = datas.bundle_id
          datas.id = i
          return datas.bundle_mode == mode.bundle_mode
        })
        this.bundleArr = this.bundleArrList.filter(function (item) {
          if (item.bundle_mode == mode.bundle_mode) {
            return item
          }
        })
        this.addonbundleArr = this.addonbundleArrList
      }
    },
    getCustomBundle () {
      let custom_index = 1;
      this.custombundle.map(data => { data.sno = custom_index++ });
      this.bundleData = this.custombundle
      this.bundleDataBackup = this.custombundle
      if (typeof(this.filterType)=='object') {
        this.getTypeFilter(this.filterType)
      }
    },
    getOTTBundle () {
      this.bundleData = this.ottbundle
      this.bundleDataBackup = this.ottbundle
      if (typeof(this.filterType)=='object') {
        this.getTypeFilter(this.filterType)
      }
    },
    getResellerCustomBundle () {
        let reseller_index = 1;
      this.reseller_custom_bundle.map(data => { data.sno = reseller_index++ });
      this.bundleData = this.reseller_custom_bundle
      this.bundleDataBackup = this.reseller_custom_bundle
      if (typeof(this.filterType)=='object') {
        this.getTypeFilter(this.filterType)
      }
    },
    createBundle () {
      var customflag = false
      this.bundleNameErrors = this.bundle_name ? [] : ['Bundle Name is required']
      this.bundleModeErrors = this.bundle_mode.bundle_mode ? [] : ['Bundle Mode is required']
      if (!this.formReady) {
        return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
      }
      if (!this.price_one_month && !this.price_three_month && !this.price_six_month && !this.price_twelve_month) {
        return Vue.notify({ text: 'Please enter price', type: 'error' })
      }
      if (this.price_one_month <= 0 && this.price_three_month <= 0 && this.price_six_month <= 0 && this.price_twelve_month <= 0) {
        return Vue.notify({ text: 'Negative values not allowed', type: 'error' })
      }
      if ((this.bundle_type == 'bundlepackage') && ((!this.package.length > 0) || !this.moq)) {
        return Vue.notify({ text: 'Please enter MoQ', type: 'error' })
      }
      if ((this.bundle_type == 'externalpackage') && (!this.external_package.length > 0)) {
        return Vue.notify({ text: 'Please select external packages', type: 'error' })
      }
      if ((this.bundle_type == 'custompackage') && (!this.custom_package.length > 0)) {
        return Vue.notify({ text: 'Please select custom packages', type: 'error' })
      }
      if (this.allowed_device == '') {
        return Vue.notify({ text: 'Please select allowed device type', type: 'error' })
      }
      if (this.pre_active && (this.cost_price == '' || this.cost_price <= 0)) {
        return Vue.notify({ text: 'Please Enter Cost Price', type: 'error' })
      }
      if (!REGEX.OrgName(this.bundle_name)) {
        return Vue.notify({ text: 'Bundle Name is Not valid', type: 'error' })
      }
      if ((this.bundle_type == 'externalpackage') && (!this.external_bundle_package_id.length > 0)) {
        return Vue.notify({ text: 'Please enter external packages ID', type: 'error' })
      }
      if (this.package_type == '' && this.bundle_type == 'bundlepackage') {
        return Vue.notify({ text: 'Please select allowed package type', type: 'error' })
      }
       if ((this.bundle_type == 'groupedpackage') && (!this.base_bundle.length > 0)) {
        return Vue.notify({ text: 'Please select atleast one base bundle', type: 'error' })
      }
      var id = this.guid()
      this.package.map(function (arg) {
        var category = ''
        arg.provider_category.map(function (thing) {
          if (category === '') {
            category = thing.id
          } else {
            category = category + ', ' + thing.id
          }
        })
        arg.provider_category = category
        arg.bundle_id = id
        delete arg.id
      })
      var vm = this
      this.external_package.map(function (args) {
        args.external_bundle_package_id = vm.external_bundle_package_id
        delete args.id
      })
      var bundlepack = []; var customflag = false; var groupflag = false
      if (this.bundle_type == 'groupedpackage') {
        this.package_type='';
        groupflag = true
        var bundle = [...this.base_bundle, ...this.addonbundle]
        bundle.map(function (prop) {
          prop.bundle_id = id
          delete prop.id
          bundlepack = [...bundlepack, ...prop.bundlepackages]
        })
      }
      this.custom_package.map(function (prop) {
        customflag = true
        prop.bundle_id = id
        delete prop.id
        bundlepack = [...bundlepack, ...prop.bundlepackages]
      })
      var uniquepack = bundlepack.filter(function (thing, index) {
        delete thing.id
        thing.bundle_id = id
        return index === bundlepack.findIndex(function (obj) {
          return obj.package_id === thing.package_id
        })
      })
      this.custom_external_package.map(function (object) {
        delete object.id

        object.bundle_id = id
      })
      var payload = {
        bundle_id: id,
        bundle_name: this.bundle_name,
        bundlepackages: this.package,
        price_one_month: this.price_one_month,
        price_six_month: this.price_six_month,
        price_three_month: this.price_three_month,
        price_twelve_month: this.price_twelve_month,
        moq: this.moq,
        add_on: this.package_type == "iptv_pack" ? 1 : 0,
        iptv: this.package_type == "iptv_pack" ? 1 : 0,
        pre_active: this.pre_active,
        cost_price: this.cost_price,
        bundle_external_packages: this.external_package,
        bundle_type: this.bundle_type,
        bundle_mode: this.bundle_mode.bundle_mode,
        bundle_custom_packages: this.custom_package,
        bundle_cost: this.bundle_cost,
        is_external_packages: this.is_external_packages,
        allowed_device: this.allowed_device,
        package_type:this.package_type,
        external_bundle_package_id: this.external_bundle_package_id,
      }
      if (this.enable_external && this.is_external_packages && (this.bundle_type != 'externalpackage')) {
        payload.bundle_custom_external_packages = this.custom_external_package
      }
      payload.bundlepackages = (customflag || groupflag) ? uniquepack : this.package
      payload.bundle_grouped_packages = bundle

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/bundle', payload).then(responseData => {

        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.getAllBundle()
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateBundle () {
      var vm = this
      this.showModal = true
      var bundleMode = this.bundle_mode.bundle_mode ? this.bundle_mode.bundle_mode : this.bundle_mode
      this.bundleModeErrors = bundleMode ? [] : ['Bundle Mode is required']
      if (!this.formReady) {
        return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
      }
      if (!this.price_one_month && !this.price_three_month && !this.price_six_month && !this.price_twelve_month) {
        return Vue.notify({ text: 'Please enter price', type: 'error' })
      }
      if ((this.bundle_type == 'bundlepackage') && ((!this.package.length > 0) || !this.moq)) {
        return Vue.notify({ text: 'Please enter MoQ', type: 'error' })
      }
      if (this.price_one_month <= 0 && this.price_three_month <= 0 && this.price_six_month <= 0 && this.price_twelve_month <= 0) {
        return Vue.notify({ text: 'Negative values not allowed', type: 'error' })
      }
      if (!this.allowed_device) {
        return Vue.notify({ text: 'Please select allowed type', type: 'error' })
      }
      if (this.pre_active && (this.cost_price == '' || this.cost_price <= 0)) {
        return Vue.notify({ text: 'Please enter cost price', type: 'error' })
      }
      if ((this.bundle_type == 'externalpackage') && (!this.external_package.length > 0)) {
        return Vue.notify({ text: 'Please select external packages', type: 'error' })
      }
      if ((this.bundle_type == 'externalpackage') && (!this.external_bundle_package_id.length > 0)) {
        return Vue.notify({ text: 'Please enter external packages id', type: 'error' })
      }
      if ((this.bundle_type == 'bundlepackage') && (!this.package.length > 0)) {
        return Vue.notify({ text: 'Please select atleast one package', type: 'error' })
      }
      if ((this.bundle_type == 'groupedpackage') && (!this.base_bundle.length > 0)) {
        return Vue.notify({ text: 'Please select atleast one base bundle', type: 'error' })
      }
      var deleted_grouped_bundle = []; var existing_arr = []; var bundle_grouped_packages = []; var pack_arr = []; var group_packs = []
      if (this.bundle_type == 'groupedpackage') {
        this.basebundle_backup.map(function (existbun) {
          if (vm.base_bundle.some(allbun => allbun.grouped_bundle_id == existbun.grouped_bundle_id)) {
            existing_arr.push(existbun.grouped_bundle_id)
          } else {
            deleted_grouped_bundle.push(existbun.grouped_bundle_id)
          }
        })
        var addon_length = this.addonbundle.length
        if (this.addonbundle_backup.length > 0) {
          this.addonbundle_backup.map(function (existaddon) {
            if (addon_length > 0) {
              if (vm.addonbundle.some(alladdon => alladdon.grouped_bundle_id == existaddon.grouped_bundle_id)) {
                existing_arr.push(existaddon.grouped_bundle_id)
              } else {
                deleted_grouped_bundle.push(existaddon.grouped_bundle_id)
              }
            } else {
              deleted_grouped_bundle.push(existaddon.grouped_bundle_id)
            }
          })
        }
        var total_group_bundle = [...this.base_bundle, ...this.addonbundle]
        if (existing_arr.length > 0) {
          bundle_grouped_packages = total_group_bundle.filter(value => !existing_arr.includes(value.grouped_bundle_id))
        } else {
          bundle_grouped_packages = total_group_bundle
        }
        if (bundle_grouped_packages.length > 0) {
          bundle_grouped_packages.map(function (bgp) {
            bgp.bundle_id = vm.bundle_id
            bgp.bundlepackages.map(function (bundlepack) {
              bundlepack.bundle_id = vm.bundle_id
              if (!pack_arr.includes(bundlepack.package_id)) {
                pack_arr.push(bundlepack.package_id)
                group_packs.push(bundlepack)
              }
            })
          })
        }
      }
      var removed_packId = []; var added_pack = []
      if (this.bundle_type == 'bundlepackage') {
        var oldPackArr = this.packBackup
        var newPackArr = this.package
        var newArr = []
        var oldArr = []
        oldPackArr.map(item => {
          oldArr.push(item)
        })
        if(!this.smspack){
        newPackArr.map(item1 => {
          newArr.push(item1)
        })
        }
        added_pack = newArr.filter(name => !oldArr.includes(name))
        const removed = oldArr.filter(name => !newArr.includes(name))
        removed.map(item2 => {
          removed_packId.push(item2.package_id)
        })
        added_pack.map(function (new_pack) {
          new_pack.bundle_id = vm.bundle_id
          var category = ''
          if(typeof(new_pack.provider_category) == 'object'){
            new_pack.provider_category.map(function (thing) {
              if (category === '') {
                category = thing.id
              } else {
                category = category + ', ' + thing.id
              }
            })
            new_pack.provider_category = category
          }
        })
      }
      this.external_package.map(function (app) {
        app.external_bundle_package_id = vm.external_bundle_package_id
        app.bundle_id = vm.bundle_id
        delete app.id
      })
      if(this.bundle_type == 'bundlepackage' && this.smspack){
        removed_packId = []; added_pack = [];
      }
      var payload = {
        bundle_name: this.bundle_name,
        deleted_package: this.bundle_type == 'groupedpackage' ? deleted_grouped_bundle : removed_packId,
        bundlepackages: this.bundle_type == 'groupedpackage' ? group_packs : added_pack,
        price_one_month: this.price_one_month,
        price_six_month: this.price_six_month,
        price_three_month: this.price_three_month,
        price_twelve_month: this.price_twelve_month,
        moq: this.moq,
        add_on: this.package_type == "iptv_pack" ? 1 : 0,
        iptv: this.package_type == "iptv_pack" ? 1 : 0,
        pre_active: this.pre_active,
        cost_price: this.cost_price,
        bundle_external_packages: this.external_package,
        bundle_type: this.bundle_type,
        bundle_mode: this.bundle_mode.bundle_mode ? this.bundle_mode.bundle_mode : this.bundle_mode,
        allowed_device: this.allowed_device,
        package_type:this.package_type,
        external_bundle_package_id: this.external_bundle_package_id,
        deleted_grouped_bundle: deleted_grouped_bundle,
        bundle_grouped_packages: bundle_grouped_packages,
      }
      this.showModal = false
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/bundle/' + this.bundle_id, payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.getAllBundle()
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })

    },
    add() {
      this.title = 'Create Bundle'
      this.bundle_name = ''
      this.moq = ''
      this.package = []
      this.external_package = []
      this.custom_package = []
      this.moq = 0
      this.external_bundle_package_id = 0
      this.price_one_month = 0
      this.price_three_month = 0
      this.price_six_month = 0
      this.price_twelve_month = 0
      this.bundleModeErrors = []
      this.customPackageArr = []
      this.custom_external_package = []
      this.base_bundle = []
      this.grouped_arr = []
      this.addonbundle = []
      this.addonbundleArr = []
      this.bundle_mode = ''
      this.is_external_packages = false
      this.one_month_flag = false
      this.three_month_flag = false
      this.six_month_flag = false
      this.twelve_month_flag = false
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
      this.add_on = false
      this.isPreactive = false
      this.pre_active = false
      this.cost_price = 0
      this.iptv = false
      this.allowed_device = ''
      this.external_bundle_package_id = ''
      this.package_type='ott_pack';
      this.getpackage(this.package_type);
      var role = this.$cookies.get('userRole')
      var vm = this
      this.enable_external = true
      if (role === 'OPERATOR' || role === 'HEPI_OPERATOR') {
        this.$http.get(config.menu.host + '/operator_setting').then(oper => {
          this.enable_external = !!oper.body[0].external_apps
        })
      }
      this.bundle_type = this.operatorRole ? 'custompackage' : 'bundlepackage'
      this.$http.get(config.menu.host + '/package').then(resp => {
       // this.packageArr = resp.body.package
        this.packageArrBk=resp.body.package
        this.packageArr.map(function (arg, i) { arg.id = i + 1 })
        var grouped_arr = []; var grouped_add_on_arr = []
        this.$http.get(config.menu.host + '/bundle').then(res => {
          res.body.map(function (item, i) {
         if(!item.bundle_name.includes('NCF')){
            item.id = i + 1
            item.grouped_bundle_id = item.bundle_id
            if (!item.add_on && item.bundle_type != 'resellerpackage' && item.bundle_type != 'groupedpackage' && item.bundlepackages.length > 0) {
              grouped_arr.push(item)
            } else if (item.add_on && item.bundle_type != 'resellerpackage' && item.bundle_type != 'groupedpackage' && item.bundlepackages.length > 0) {
              grouped_add_on_arr.push(item)
            }
          }
          })
          this.bundleArrList = grouped_arr
          this.addonbundleArrList = grouped_add_on_arr
        })
      })
    },
    edit (row) {
      this.smspack = false
      this.getBundlePrice({ bundle_mode: row.bundle_mode })
      if (row.bundle_mode == 'One Month' && row.bundle_type != 'externalpackage' && !row.bundle_name.includes('NCF')) {
        this.isPreactive = true
      } else {
        this.pre_active = false
        this.isPreactive = false
      }
      var role = this.$cookies.get('userRole')
      this.enable_external = true
      if (role === 'OPERATOR' || role === 'HEPI_OPERATOR') {
        this.$http.get(config.menu.host + '/operator_setting').then(oper => {
          this.enable_external = !!oper.body[0].external_apps
        })
      }
      var vm = this
      vm.$http.get(config.menu.host + '/provider').then(resp => {
        vm.img_arr = []
        var pack = ''; var custompack = ''; var customexternalpack = ''
        row.bundlepackages.map(function (thing) {
          var filter = resp.body.filter(function (prop) {
            return (prop.provider_id == thing.provider_id)
          })
          if (!vm.img_arr.includes(filter[0].logo_url)) {
            vm.img_arr.push(filter[0].logo_url)
          }
          if (pack === '') {
            pack = thing.package_name
          } else {
            pack = pack + ',' + thing.package_name
          }
        })
        row.bundle_custom_packages.map(function (thing) {
          if (custompack === '') {
            custompack = thing.bundle_name
          } else {
            custompack = custompack + ',' + thing.bundle_name
          }
        })
        row.bundle_custom_external_packages.map(function (argument) {
          if (customexternalpack === '') {
            customexternalpack = argument.bundle_name
          } else {
            customexternalpack = customexternalpack + ',' + argument.bundle_name
          }
        })
        var bun_filter = resp.body.filter(function (bunfil) {
          if (row.bundle_name.includes(bunfil.brand + '-')) {
            vm.smspack = true
          }
        })
        this.$http.get(config.menu.host + '/external_app').then(re => {
          this.externalPackageArr = re.body
          this.externalPackageArr.map(function (input) {
            row.bundle_external_packages.map(function (data) {
              if (input.external_app_id == data.external_app_id) {
                data.id = input.id
              }
            })
          })
        })
        // var add_on_pack = '', grouped_pack = ''
        // if (!re_bundle.iptv) {
        //     if (grouped_pack === '') {
        //       grouped_pack = re_bundle.bundle_name
        //     } else {
        //       grouped_pack = grouped_pack + ' , ' + re_bundle.bundle_name
        //     }
        //    } else {
        //     if (add_on_pack === '') {
        //       add_on_pack = re_bundle.bundle_name
        //     } else {
        //       add_on_pack = add_on_pack + ' , ' + re_bundle.bundle_name
        //     }
        //    }
        //    vm.base_bundle = grouped_pack
        //    vm.addonbundle = add_on_pack
        // })
        var add_on_pack = []; var grouped_pack = []
        row.bundle_grouped_packages.map(function (re_bundle) {
          if (!re_bundle.iptv) {
            grouped_pack.push(re_bundle)
          } else {
            add_on_pack.push(re_bundle)
          }
        })
        vm.base_bundle = grouped_pack
        vm.addonbundle = add_on_pack
        this.external_package = row.bundle_external_packages
        this.package = this.smspack ? pack : row.bundlepackages;
        this.packBackup = row.bundlepackages
        this.custom_package = custompack
        this.custom_package_external = customexternalpack
        this.title = 'Update Bundle'
        this.bundle_id = row.bundle_id
        this.bundle_name = row.bundle_name
        this.moq = row.moq
        this.base_bundle = grouped_pack
        this.basebundle_backup = grouped_pack
        this.addonbundle = add_on_pack
        this.addonbundle_backup = add_on_pack
        this.price_one_month = row.price_one_month
        this.price_three_month = row.price_three_month
        this.price_six_month = row.price_six_month
        this.price_twelve_month = row.price_twelve_month
        this.package_type= (row.add_on && row.iptv == true ) ? "iptv_pack" : "ott_pack";
        this.getpackage(this.package_type);
        this.add_on=row.add_on;
        this.iptv=row.iptv
        this.pre_active = row.pre_active
        this.cost_price = row.cost_price
        this.one_month_flag = false
        this.three_month_flag = false
        this.six_month_flag = false
        this.twelve_month_flag = false
        this.bundle_mode = row.bundle_mode ? row.bundle_mode : ''
        this.is_external_packages = (row.is_external_packages) ? row.is_external_packages : false
        this.allowed_device = row.allowed_device
        // this.package_type =row.package_type
        this.external_bundle_package_id = row.external_bundle_package_id
        if (this.bundle_mode) {
          var mode_key = this.mode[row.bundle_mode]
          this[mode_key] = true
        }
        this.customPackFlag = (row.bundle_type == 'custompackage')
        this.bundle_type = row.bundle_type
        // this.isshowgrid = false
        // // this.isshowForm = true
        this.isCreate = false
        this.isUpdate = true
        this.showModal = true
              if(this.smspack){
          this.package = pack
        }else if (!this.smspack) {
          vm.packageArr.map(function (item) {
            row.bundlepackages.map(function (entity) {
              if (item.package_id == entity.package_id) {
                entity.id = item.id
              }
            })
          })
          this.package = row.bundlepackages
        }
        vm.base_bundle.map(function (item) {
          vm.bundleArr.map(function (entity) {
            if (item.grouped_bundle_id == entity.bundle_id) {
              entity.id = item.id
            }
          })
        })
        vm.addonbundleArr.map(function (entity) {
          vm.addonbundle.map(function (item) {
            if (item.grouped_bundle_id == entity.bundle_id) {
              entity.id = item.id
            }
          })
        })
      })
    },
    list () {

      if (this.tabValue == 1) {
        this.isshowgrid = true
        this.isshowForm = false
        this.showModal = false
        this.getCustomBundle()
      }
      if (this.tabValue == 0) {
        this.isshowgrid = true
        this.isshowForm = false
        this.showModal = false
        this.getOTTBundle()
      }
      if (this.tabValue == 2) {
        this.isshowgrid = true
        this.isshowForm = false
        this.showModal = false
        this.getResellerCustomBundle()
      }
      // this.isshowForm = false
      // this.isshowgrid = true
      // this.getAllBundle()

      this.bundleNameErrors = []
      this.bundleModeErrors = []
      this.isShowPackages = false
      this.isShowBundles = false
      this.filterType = ''
    },
    search: debounce(function (term) {
      this.term = term
      this.filterType = ''
      // var getData = search(this.term, JSON.parse(this.bkdata))
      // this.bundleData = getData
    }, 400),
  },
}
</script>
<style type="text/css">
h4 {
  line-height: 0.2;
}

.packcard {
  height: 170px;
  padding: 0.1px;
}

.separator {
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #ddbf1a;
}

.cursor {
  cursor: pointer;
  padding-left: 120px;
  position: absolute;
  right: 2px;
  top: 5;
}

.btn {
  border-radius: 0.5rem;
  height: 3rem;
  margin-top: 19px;
  background-color: #878787 !important;
}

.va-modal--size-large {
  width: 100%;
}

.image {
  max-width: 10%;
  height: auto;
}

.top {
  margin-top: -48px;
  margin-left: 96%;
}

.model_export {
  float: right;
  margin-top: 18%;
  margin-right: 175%;
}

.header {
  color: white !important;
  text-transform: capitalize;
  margin-left: 35px;
}

h1 {
  font-weight: bold;
  font-size: 25px;
  margin-top: -5px;
}

.header h6 {
  font-size: 17px;
  font-weight: 100;
}

.color {
  position: fixed;
  margin-top: -20px;
  margin-left: -24px;
  width: 768px;
  z-index: 1;
}

.data_table {
  margin-top: 86px;
  overflow-x: clip;
  overflow-y: auto;
  height: 429px;
}

.card {
  position: relative;
  height: 84px;
  border-top-left-radius: 34px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.va-modal {
  border-radius: 35px;
}

.va-card__body {
  overflow-x: clip;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

</style>
